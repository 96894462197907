<template>
  <!--begin::Contract Listing-->
  <div class="contract-template">
    <PageHeaderCount
      moduleType="contract"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_ltx_contracts"
      countkey="contract_status_count"
    ></PageHeaderCount>
    <ListingTemplate
      :customClass="'contract-listing'"
      v-if="getPermission('contract:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0 my-auto">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('contract:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="updateBulkAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex class="pt-0 mxw-200">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <!-- <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle> -->
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_ltx_contracts
                          }}</template>
                          <template v-else>{{
                            item.contract_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-flex>
              <v-flex
                class="pt-0 pr-0 justify-flex-end d-flex contract-listing-button-right"
              >
                <template v-if="false && getPermission('contract:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                    :to="getDefaultRoute('contract.create')"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                  <v-btn
                    v-if="false"
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="exportContract"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-database-export</v-icon> Export
                  </v-btn>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <!--  <v-btn
                  v-if="!isEngineerChannel()"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'contract',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button pr-0"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn> -->
                <v-menu
                  v-if="false"
                  content-class="custom-menu-list"
                  offset-y
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-menu
                  max-height="400"
                  max-width="260"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                    v-on:change="updateVisible()"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          v-on:change="updateVisible()"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <v-menu offset-y left v-if="false">
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          depressed
                          :disabled="dataLoading"
                          class="ml-2 custom-bold-button"
                          color="cyan white--text"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          {{ entityObject.name }}
                        </v-btn>
                      </template>
                      <span>Entity</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in entityList"
                      :key="index"
                      link
                      v-on:click="updateEntity(item)"
                    >
                      <v-list-item-title class="font-weight-600">{{
                        item.name
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <PageTips v-if="false" module="contract"></PageTips>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Contract"
          :basicSearchFields="[
            'barcode',
            'reference',
            'job_title',
            /*    'sales',
            'prepared_by', */
            /* 'attention', */
            'company_name',
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-html="cols.headerName"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        ></div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData.length > 0">
                  <tr
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    class="contract-listing-row"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        :style="{ 'max-width': cols.width }"
                        v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <div class="d-inline-grid" style="position: relative">
                            <Barcode
                              route="contract.detail"
                              :barcode="data.barcode"
                              :id="data.id"
                            ></Barcode>
                            <Barcode
                              color="orange"
                              route="quotation.detail"
                              :barcode="data.quotation_barcode"
                              :id="data.quotation_id"
                            ></Barcode>

                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  style="
                                    position: absolute;
                                    top: 7px;
                                    right: -25px;
                                  "
                                  v-if="data?.has_tool == 1"
                                  color="green darken-4"
                                  small
                                  >mdi-tools</v-icon
                                >
                              </template>
                              <span>Tools</span>
                            </v-tooltip>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'date'">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Started At: </b>
                            <template v-if="data.started_at">
                              {{ formatDate(data.started_at) }}
                            </template>
                            <em class="text-muted" v-else> no started at</em>
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Finished At: </b>
                            <template v-if="data.finished_at">
                              {{ formatDate(data.finished_at) }}
                            </template>
                            <em class="text-muted" v-else> no finished at</em>
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'job_title'">
                          <div class="contract-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Title: </b>{{ data.title }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Reference: </b>
                              <template v-if="data.reference">{{
                                data.reference
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Reference</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b
                                >Visit Count:
                                <template v-if="data && data.visit_count > 0">
                                  <span
                                    class="font-size-16 ml-1 count-radius cyan white--text"
                                    >{{ data.visit_count }}</span
                                  >
                                </template>
                                <span
                                  v-else
                                  style="
                                    background-color: #93959f !important;
                                    color: white;
                                  "
                                  class="ml-1 count-radius white-text"
                                  >0</span
                                ></b
                              >
                            </p>
                            <p
                              class="m-0 custom-nowrap-ellipsis-two-line"
                              v-if="data.billing"
                            >
                              <b>Company Address: </b>
                              <template v-if="data.billing.property_address"
                                >{{ data.billing.property_address }},
                              </template>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'no_of_services'">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b
                              ><span
                                class="font-size-18 mr-1 count-radius cyan white--text"
                                >{{ data.visit_count }}</span
                              >
                              Total</b
                            >
                          </p>
                          <p
                            class="m-0 custom-nowrap-ellipsis green--text text--darken-4"
                          >
                            <b
                              ><span
                                class="font-size-18 mr-1 count-radius green white--text"
                                >{{ data.completed_count }}</span
                              >
                              Completed</b
                            >
                          </p>
                          <p
                            class="m-0 custom-nowrap-ellipsis yellow--text text--darken-4"
                          >
                            <b
                              ><span
                                class="font-size-18 mr-1 count-radius yellow darken-4 white--text"
                                >{{ data.pending_count }}</span
                              >
                              Pending</b
                            >
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis red--text">
                            <b
                              ><span
                                class="font-size-18 mr-1 count-radius red white--text"
                                >{{ data.cancelled_count }}</span
                              >
                              Cancelled</b
                            >
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'basic_detail'">
                          <div class="contract-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Reference: </b>
                              <template v-if="data.reference">{{
                                data.reference
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Reference</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Sales representative: </b>
                              <template v-if="data.sales">{{
                                data.sales
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Sales representative</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Prepared by: </b>
                              <template v-if="data.prepared_by">{{
                                data.prepared_by
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Prepared by</em
                                ></template
                              >
                            </p>

                            <p class="m-0 custom-nowrap-ellipsis d-flex">
                              <b> Rating: </b>
                              <template v-if="data.rating"
                                ><v-rating
                                  readonly
                                  dense
                                  v-model.trim="data.rating"
                                  background-color="orange lighten-3"
                                  color="orange"
                                ></v-rating
                              ></template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Rating</em
                                ></template
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'status'">
                          <div class="contract-listing-status">
                            <div
                              class="d-flex flex-column flex-wrap justify-center align-start"
                            >
                              <v-chip
                                label
                                small
                                :color="data?.qt_status_color"
                                :text-color="data?.qt_status_textcolor"
                                class="ml-2 text-uppercase font-weight-600"
                              >
                                {{ data?.qt_status_text }}
                              </v-chip>
                              <v-chip
                                v-if="data?.invoice_converted == 1"
                                label
                                small
                                color="green"
                                text-color="white"
                                class="my-2 ml-2 text-uppercase font-weight-600"
                                >Converted to Invoice
                              </v-chip>
                              <v-chip
                                v-if="data?.visit_converted == 1"
                                label
                                small
                                color="orange"
                                text-color="white"
                                class="ml-2 text-uppercase font-weight-600"
                                >Converted to Visit
                              </v-chip>
                            </div>
                            <div v-if="false" class="mb-1">
                              <template v-if="data.is_contract">
                                <v-tooltip
                                  top
                                  content-class="custom-top-tooltip"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                      v-bind="attrs"
                                      v-on="on"
                                      color="green lighten-1 white--text"
                                      class="mx-2 custom-status font-weight-600 custom-grey-border d-inline-table d-inline text-uppercase justify-center font-10"
                                      label
                                      small
                                    >
                                      CONTRACT
                                    </v-chip>
                                  </template>
                                  <span>Contract</span>
                                </v-tooltip>
                              </template>
                            </div>
                            <div class="mb-1">
                              <template v-if="data.is_revised">
                                <v-tooltip
                                  top
                                  content-class="custom-top-tooltip"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                      v-bind="attrs"
                                      v-on="on"
                                      color="red lighten-4"
                                      class="mx-2 custom-status font-weight-600 custom-grey-border d-inline-table d-inline text-uppercase justify-center font-10"
                                      label
                                      small
                                    >
                                      REVISED
                                    </v-chip>
                                  </template>
                                  <span>Contract is Revised</span>
                                </v-tooltip>
                              </template>
                            </div>
                            <div class="mb-1">
                              <template
                                v-if="lodash.isEmpty(data.revise) === false"
                              >
                                <v-tooltip
                                  top
                                  content-class="custom-top-tooltip"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                      v-bind="attrs"
                                      v-on="on"
                                      color="teal lighten-4 black--text"
                                      class="mx-2 custom-status font-weight-600 custom-grey-border d-inline-table d-inline text-uppercase justify-center font-10"
                                      label
                                      small
                                    >
                                      REVISION
                                    </v-chip>
                                  </template>
                                  <span
                                    >Revision of {{ data.revise.barcode }}</span
                                  >
                                </v-tooltip>
                              </template>
                            </div>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'company_info'">
                          <div class="contract-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Display Name: </b>
                              {{
                                data &&
                                data.customer &&
                                data.customer.display_name
                              }}
                              <!-- {{ data.customer.display_name }} -->
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Company: </b>
                              {{
                                data &&
                                data.customer &&
                                data.customer.company_name
                              }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Email: </b>
                              <template
                                v-if="data?.billing_person?.primary_email"
                              >
                                {{ data.billing_person.primary_email }}
                              </template>
                              <em v-else class="text-muted"> no email </em>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Phone: </b>
                              <template
                                v-if="data?.billing_person?.primary_phone"
                              >
                                {{ data.billing_person.primary_phone }}
                              </template>
                              <em v-else class="text-muted"> no phone </em>
                            </p>
                            <template
                              v-if="
                                false && data.customer.contact_person_count > 1
                              "
                            >
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class="ml-2"
                                v-on:click.prevent.stop="
                                  customerRedirect(data.customer.id)
                                "
                              >
                                <span class="font-size-16 font-weight-500">
                                  +{{
                                    data.customer.contact_person_count - 1
                                  }}
                                  More</span
                                >
                              </v-chip>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'amount'">
                          <div class="contract-listing-amount">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Sub Total: </b>
                              {{ $accountingJS.formatMoney(data.sub_total) }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Tax: </b>
                              {{ $accountingJS.formatMoney(data.tax_amount) }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Total: </b>
                              {{ $accountingJS.formatMoney(data.total) }}
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDate(data.added_at) }}
                              {{ formatDateStartTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="customThead.length" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no contract at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Contract</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <v-col md="6">
                    <v-menu
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          flat
                          label="Dates Range"
                          hide-details
                          solo
                          clearable
                          v-on:click:clear="dates = []"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :value="formattedDate"
                          color="cyan"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="cyan"
                        range
                        v-model="dates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <Datepicker
                        v-if="field == 'open_till'"
                        solo
                        flat
                        v-model.trim="listingSearch[field]"
                        label="Open Still"
                        v-on:update:date-picker="setDefaultDate($event)"
                      ></Datepicker>

                      <v-text-field
                        v-else
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="
                          field == 'sales'
                            ? 'Sales Person'
                            : field == 'customer_detail[value]'
                            ? 'Customer Details[Phone Number]'
                            : getFilterLabel(field, 'Contract')
                        "
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <ImportTemplate
          :dialog="importDialog"
          sample-url="contract/import-sample"
          api-url="contract/import"
          title="Import Contract"
          v-on:close="importDialog = false"
        ></ImportTemplate>

        <template v-if="entityDialog">
          <EntityDialog
            :entity-dialog="entityDialog"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectEntity="selectEntity"
          ></EntityDialog>
        </template>
        <template v-if="entity && customerDialog">
          <CustomerDialog
            :entity="entity"
            :customerDialog="customerDialog"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomer="selectCustomer"
          ></CustomerDialog>
        </template>
        <template v-if="customerPersonDialog">
          <CustomerPersonDialog
            disabled-auto-select
            :customerPersonDialog="customerPersonDialog"
            :customer="customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerPerson="selectCustomerPerson"
          ></CustomerPersonDialog>
        </template>
        <template v-if="customerPropertyDialog">
          <CustomerPropertyDialog
            disabled-auto-select
            :customerPropertyDialog="customerPropertyDialog"
            :customer="customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerProperty="selectCustomerProperty"
          ></CustomerPropertyDialog>
        </template>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Contract Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import ContractMixin from "@/core/lib/contract/contract.mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, DOWNLOAD } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import { saveAs } from "file-saver";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
// import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import EntityDialog from "@/view/pages/partials/Select-Entity.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import JwtService from "@/core/services/jwt.service";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import ImportTemplate from "@/view/pages/partials/Import-Template.vue";
//import { map } from "lodash";
import moment from "moment-timezone";
export default {
  mixins: [CommonMixin, ListingMixin, ContractMixin],
  name: "contract-list",
  data() {
    return {
      exportLoading: false,
      dates: [],
      pageModule: "contract-listing",
      routeAPI: "contract",
      routeName: "contract",
      routeDetailName: "contract.detail",
      importDialog: false,
      status: "all",
      statusList: [],
      customThead: [],
      moreActions: [
        {
          title: "Import Contract(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },
        /*{
          title: "Export Contract(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },*/
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export",
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Mark as Draft",
          icon: "mdi-check-all",
          action: "draft",
        },
        {
          title: "Mark as Accepted",
          icon: "mdi-check-all",
          action: "accepted",
        },
        {
          title: "Mark as Rejected",
          icon: "mdi-check-all",
          action: "rejected",
        },
        {
          title: "Mark as Pending",
          icon: "mdi-email-receive",
          action: "pending",
        },
        /*{
          title: "Send as Email",
          icon: "mdi-email-send",
          action: "sent"
        },*/
        {
          title: "Mark as Cancelled",
          icon: "mdi-check-all",
          action: "cancelled",
        },
        {
          title: "Mark as Closed",
          icon: "mdi-check-all",
          action: "closed",
        },
      ],
    };
  },
  components: {
    PageTips,
    draggable,
    EntityDialog,
    // CustomStatus,
    PageHeaderCount,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
    CustomerDialog,
    CustomerPropertyDialog,
    CustomerPersonDialog,
    Datepicker,
    ImportTemplate,
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = [];
        }
      },
    },
  },
  methods: {
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    selectCustomerDialog() {
      this.customerDialog = true;
    },
    selectCustomerPersonDialog() {
      this.customerPersonDialog = true;
    },
    selectCustomerPropertyDialog() {
      this.customerPropertyDialog = true;
    },
    resetAll() {
      this.entity = 1;
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.entityDialog = false;
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectEntity(param) {
      this.entity = param;
      this.closeDialog();
      this.$nextTick(() => {
        this.selectCustomerDialog();
      });
    },
    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkContract();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkContract();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkContract();
    },

    /* exportContract() {
      let downloadURL = process.env.VUE_APP_API_URL + "contracts/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      window.open(downloadURL, "_blank");
    }, */

    exportContract() {
      //console.log(row,"rowhhh");
      //console.log(this.searchableArray,"searchableArray");

      let downloadURL = process.env.VUE_APP_API_URL + "contracts/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      //downloadURL.searchParams.append("search", this.queryParams.search);
      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        // console.log(index, "indexdd")

        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }

        //  downloadURL.searchParams.append(index, row);
      });
      window.open(downloadURL, "_blank");
    },

    customerRedirect(customerId) {
      this.$router.push(
        this.getDefaultRoute("customer.detail", {
          params: { id: customerId },
        })
      );
    },
    checkContract() {
      const _this = this;
      if (_this.customer > 0 && _this.customerProperty > 0) {
        _this.$router.push(
          _this.getDefaultRoute("contract.create", {
            query: {
              customer: _this.customer ?? undefined,
              property: _this.customerProperty ?? undefined,
            },
          })
        );
      }
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkContractExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("contract");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    updateBulkAction(param) {
      const _this = this;
      switch (param) {
        case "draft":
          _this.bulkContractUpdate({
            contracts: _this.selectedRows,
            status: 1,
          });
          break;
        case "accepted":
          _this.bulkContractUpdate({
            contracts: _this.selectedRows,
            status: 2,
          });
          break;
        case "rejected":
          _this.bulkContractUpdate({
            contracts: _this.selectedRows,
            status: 3,
          });
          break;
        case "pending":
          _this.bulkContractUpdate({
            contracts: _this.selectedRows,
            status: 4,
          });
          break;
        case "sent":
          _this.bulkContractUpdate({
            contracts: _this.selectedRows,
            status: 5,
          });
          break;
        case "cancelled":
          _this.bulkContractUpdate({
            contracts: _this.selectedRows,
            status: 6,
          });
          break;
        case "closed":
          _this.bulkContractUpdate({
            contracts: _this.selectedRows,
            status: 7,
          });
          break;
      }
    },
    bulkContractUpdate(requestObject) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "contract",
          data: requestObject,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
        });
    },
    bulkContractExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "contracts/export",
        })
        .then(({ data }) => {
          saveAs(data, "contracts-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.resetAll();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Contract",
      },
    ]);
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    // _this.defaultColDefs = [
    //   /* {
    //     headerName: "",
    //     field: "id",
    //     sort: null,
    //     visible: true,
    //     fixed: true,
    //     sortable: false,
    //     checkbox: true,
    //     order: 0,
    //     width: "50px",
    //   }, */
    //   {
    //     headerName: "Contract #",
    //     field: "barcode",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 1,
    //     width: "200px",
    //   },
    //   {
    //     headerName: "Job Title / Company Address",
    //     field: "job_title",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 1,
    //     width: "300px",
    //   },
    //   {
    //     headerName: "Basic Details",
    //     field: "basic_detail",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 1,
    //     width: "300px",
    //   },
    //   {
    //     headerName: "Dates",
    //     field: "date",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 1,
    //     width: "200px",
    //   },
    //   {
    //     headerName: "Customer Info",
    //     field: "company_info",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 3,
    //     width: "300px",
    //   },
    //   {
    //     headerName: "Status",
    //     field: "status",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 3,
    //     width: "200px",
    //   },
    //   {
    //     headerName: "Amount",
    //     field: "amount",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 3,
    //     width: "200px",
    //   },
    //   {
    //     headerName: "Created Time",
    //     child: "created_at",
    //     field: "added_at",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 9,
    //     width: "170px",
    //   },
    //   {
    //     headerName: "Last Modified Time",
    //     child: "modified_at",
    //     field: "updated_at",
    //     sort: null,
    //     visible: false,
    //     fixed: false,
    //     sortable: false,
    //     order: 10,
    //     width: "170px",
    //   },
    // ];

    // const defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
    //   visible: true,
    // });

    // _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    //_this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
